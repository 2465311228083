:root {
  --content-max-width-lg: 1100px;
  --content-max-width-md: 740px;
  --content-max-width-sm: 500px;
  --logo-height: 80px;
  --item-spacing: 2em;
  --item-padding: 1.5em;
  --item-text-margin: 12px;
  --color-text: #000000;
  --color-ep-main: #232323;
  --color-card: #ffffff;
}

body, html, .app {
  margin: 0 !important;
  padding: 0 !important;
}

body * {
  user-select: none !important;
}

.banner {
  position: absolute;
  top: -10px;
  z-index: -1;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-logo {
  margin: 2.5em 0;
  height: 80px;
}

.store-badge-img {
  height: 48px;
}

.header .store-badge {
  margin: 0 1em;
}

.header .store-badge-container {
  margin-bottom: 1em;
}

.game-list .store-badge-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-list .store-badge {
  margin: 0 0.3em;
}

.game-list {
  padding: 16px;
}

.game-list-content {
  max-width: var(--content-max-width-lg);
  margin: 0 auto;
}

.game-list-content a {
  color: var(--color-text) !important;
  text-decoration: none !important;
}

.game-list-item-paper {
  box-shadow: 0 4px 8px -2px lightgray;
  border-radius: 2px;
  height: 100%;
  background-color: var(--color-card);
}

.game-list-item-paper.ep-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-ep-main);
  padding: 1em;
}

.ep-card-logo {
  height: 56px;
  margin: 0.5em 0 1.5em 0;
}

.game-list-item {
  display: flex;
  align-items: start;
  justify-content: start;
  padding: var(--item-padding);
  height: 100%;
}

.game-list-item-img {
  height: 100px;
  margin-right: var(--item-padding);
}

.game-list-item-info {
  margin: 0;
}

.game-list-item-title {
  text-transform: lowercase;
  font-variant: small-caps;
  font-weight: bold;
  margin-top: -4px;
  margin-bottom: var(--item-text-margin);
}

.game-list-item-desc {
  font-size: 0.9em;
  margin-top: var(--item-text-margin);
  line-height: 1.1em;
}

.game-list-item-play {
  font-size: 0.8em;
  font-weight: bold;
  margin-top: var(--item-text-margin);
}

.footer {
  text-align: center;
  margin: 2em 0;
}

@media (max-width: 1199px)
{
  .game-list-content {
    max-width: var(--content-max-width-md);
  }
}

@media (max-width: 899px)
{
  .game-list-content {
    max-width: var(--content-max-width-sm);
  }
}

@media (max-width: 799px)
{
  .header {
    aspect-ratio: 3.7;
  }

  .header-logo {
    margin: 1.5em 0;
    height: 35%;
  }

  .header .store-badge {
    margin: 0 0.3em;
  }

  .header .store-badge-container {
    margin-bottom: 0;
  }
}

@media (max-width: 599px)
{
  .header {
    aspect-ratio: 2.4;
  }

  .header-logo {
    margin: 1em 0;
    height: 35%;
  }

  .game-list-content {
    max-width: var(--content-max-width-sm);
  }
}